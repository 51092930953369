import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../util/store/slices/authSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { FiUser } from "react-icons/fi";
import { IoMdNotificationsOutline } from "react-icons/io";
import * as API from "../../util/url";
import moment from "moment";
import parse from "html-react-parser";

const LandingBanner = () => {
  const { userName, accessToken } = useSelector((state) => state.auth);
  const disptach = useDispatch();
  const navigate = useNavigate();
  const userCred = localStorage.getItem("bestUrl");
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState([]);
  const [searchData, setSearchData] = useState("");

  useEffect(() => {
    disptach(
      setAuth({
        accessToken: localStorage.getItem("bestUrl"),
        userName: localStorage.getItem("userName"),
      })
    );
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem("bestUrl");
    navigate("/signin");
  };

  const getNotification = async () => {
    try {
      let response = await API.getUserNotification();
      console.log(response, "rsp");
      setCount(response.data.map.unread);
      setMessage(response.data.map.notification);
    } catch (error) {
      console.log(error);
    }
  };
  const updateNotificationHandler = async (id) => {
    try {
      let response = await API.updateNotification({
        id: [id],
      });
      setCount(response.data.map.unread);
    } catch (error) {
      console.log(error);
    }
  };

  const searchHandler = (data) => {
    console.log(searchData, "sd");
    navigate("/marketplace", { state: searchData });
  };

  useEffect(() => {
    getNotification();
  }, []);

  return (
    <div>
      <div className="first-section">
        {/* navbar */}
        <nav
          className="navbar navs navbar-expand-lg"
          style={{
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <div className="container">
            <NavLink
              to="/"
              className="navbar-brand"
              style={{ color: "Black", fontSize: "30px", fontWeight: "700" }}
            >
              DNGH
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              style={{ justifyContent: "end" }}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  text-center">
                {/* <li className="nav-item">
            <a className="nav-link active ms-5" aria-current="page" href="#">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link me-5" href="#">
              Domain
            </a>
          </li> */}
                <li className="nav-item">
                  <NavLink
                    className="nav-link me-5"
                    to={userCred !== null ? "/marketplace" : "/signin"}
                  >
                    Marketplace
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link me-5"
                    to={userCred !== null ? "/dashboard" : "/signin"}
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to={userCred !== null ? "/blog" : "/signin"}
                    className="nav-link me-5"
                  >
                    Blog
                  </NavLink>
                </li>
              </ul>

              {accessToken !== null ? (
                <div className="d-lg-flex " style={{ alignItems: "baseline" }}>
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        style={{
                          background: "transparent",
                          border: "none",
                          fontSize: "25px",
                          padding: "5px",
                          padding: "5px 10px",
                          marginRight: "25px",
                          color: "black",
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <IoMdNotificationsOutline
                            style={{ fontSize: "24px" }}
                          />
                          <span className="notifyCount">{count}</span>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dropdown-menu dropdown-menu-end animate slideIn mt-4 scrollable-element"
                        // style={{ height: "300px", overflowY: "scroll" }}
                        style={{
                          height: "auto",
                          overflowY: "scroll",
                          width: "10rem",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                          backgroundColor: "white",
                        }}
                      >
                        {message.length === 0 ? (
                          <div className="px-2 py-1 stripe">
                            <NavLink
                              className="  "
                              style={{ color: "black" }}
                              to="/dashboard"
                            >
                              no notification
                            </NavLink>
                          </div>
                        ) : (
                          <>
                            {message.map((data) => {
                              return (
                                <div
                                  className="px-2 py-1 stripe m-2"
                                  onClick={() => {
                                    navigate(data.href, { state: data.reqId });
                                    updateNotificationHandler(data.id);
                                  }}
                                  style={{
                                    background: "#f3f7f9",
                                    width: "260px",
                                  }}
                                >
                                  <div
                                    className="  "
                                    style={{ color: "white", fontSize: "14px" }}
                                  >
                                    {parse(data.message)}
                                    <br />
                                    {moment(data.date).format("ll")}
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                      <Dropdown.Toggle
                        style={{
                          background: "transparent",
                          border: "none",
                          fontSize: "25px",
                          color: "black",
                        }}
                      >
                        <FiUser className="fiuser-profile" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dropdown-menu dropdown-menu-end animate slideIn mt-4"
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      >
                        <div className="px-2 py-1" style={{ fontSize: "15px" }}>
                          Welcome{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {userName}
                          </span>
                        </div>
                        <Dropdown.Divider />
                        <div className="px-2 py-1 stripe">
                          <NavLink
                            className="  me-5"
                            style={{ color: "black" }}
                            to="/dashboard"
                          >
                            Dashboard
                          </NavLink>
                        </div>
                        <div className="px-2 py-1 stripe">
                          <NavLink
                            className=" me-5"
                            style={{ color: "black" }}
                            to="/dashboard/profile"
                          >
                            Profile
                          </NavLink>
                        </div>

                        <div className="px-2 py-1 stripe">
                          <div
                            className=" me-5"
                            style={{ cursor: "pointer", color: "black" }}
                            onClick={logoutHandler}
                          >
                            Logout
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div className="button create-listing-btn">
                      <NavLink to="/listing" className="sign">
                        Create Listing
                      </NavLink>
                    </div>
                  </>
                </div>
              ) : (
                <div className="button">
                  <NavLink to={"/signin"} className="sign">
                    Login
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </nav>
        {/* navbar complete */}
        {/* banner */}
        <section id="banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12 text-center banner-info">
                <h2 className="banner-head">
                  The fastest growing marketplace to <br />
                  buy and sell <span>Domains.</span>
                </h2>
                <p className="banner-para">
                  Securely Buy and Sell Digital Assets Using Multiple Payment
                  Methods - CC, DC, Reward pods, and 8 Additional
                  Cryptocurrencies.
                </p>
                <div className="banner-button">
                  <div className="d-flex search">
                    <input
                      type="text"
                      placeholder="Domains"
                      onChange={(e) => setSearchData(e.target.value)}
                    />
                    <div
                      className="outer-circle"
                      onClick={searchHandler}
                      style={{
                        // backgroundImage:
                        //   "linear-gradient(160deg, #4295ce, #0a456f)",
                        backgroundColor: "#224bee",
                        zIndex: 1,
                        borderRadius: "50%",
                        height: "48px",
                        width: "48px",
                        marginTop: "2.5px",
                        marginLeft: "-52px",
                        cursor: "pointer",
                      }}
                    >
                      <div className="inner-circle">
                        <img
                          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393278/search_e4nnj0.png"
                          alt="search"
                          style={{
                            height: "16px",
                            width: "16px",
                            marginTop: "9px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <form action="">
          <div className="search1">
            <input type="text" />
          </div>
        </form> */}

                <div className="first-section-videosection">
                  <video muted autoPlay loop className="first-section-video">
                    <source
                      src="https://res.cloudinary.com/dbtsrjssc/video/upload/v1727179448/banner-video_eerc09.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* complete banner */}
        {/* video */}
      </div>
    </div>
  );
};

export default LandingBanner;
