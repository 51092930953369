import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const userCred = localStorage.getItem("bestUrl");
  return (
    <div>
      <>
        <div id="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-6 col-12">
                <div className="left-side-foot">
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="footer-block footer-first">
                        <h3 className="footer-heading">
                          Quick links
                          {/* <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393863/icons8-play-30_ux4ngf.png"
                            alt="play"
                            style={{ marginTop: "-2px", width: 15, height: 15 }}
                          /> */}
                        </h3>
                        <ul className="footer-list">
                          <li>
                            <NavLink to={"/"}>Home</NavLink>
                          </li>

                          <li>
                            <NavLink
                              to={
                                userCred !== null ? "/marketplace" : "/signin"
                              }
                            >
                              Marketplace
                            </NavLink>{" "}
                          </li>
                          <li>
                            {/* <a href="#">Blog</a> */}
                            <NavLink
                              to={userCred !== null ? "/blog" : "/signin"}
                            >
                              Blog
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="footer-block">
                        <h3 className="footer-heading">
                          Policy
                          {/* <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393863/icons8-play-30_ux4ngf.png"
                            alt="play"
                            style={{ marginTop: "-2px", width: 15, height: 15 }}
                          /> */}
                        </h3>
                        <ul className="footer-list">
                          <li>
                            <NavLink to={"/terms-of-service"}>
                              Terms of Service
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/privacy-policy"}>
                              Privacy Policy
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      <div className="footer-block footer-first help">
                        <h3 className="footer-heading">
                          Help &amp; Support
                          {/* <img
                            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1707393863/icons8-play-30_ux4ngf.png"
                            alt="play"
                            style={{ marginTop: "-2px", width: 15, height: 15 }}
                          /> */}
                        </h3>
                        <ul className="footer-list">
                          <li>
                            <NavLink to={"/buyer-guidelines"}>
                              Buyer Guidelines
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={"/seller-guidelines"}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              Seller Guidelines
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={"/faq"}>FAQ's</NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                      {/* <div className="footer-block">
                  <select id="language">
                    <option value="english">English</option>
                    <option value="Spanish">Spanish</option>
                    <option value="French">French</option>
                    <option value="Russian">Russian</option>
                  </select>
                </div> */}
                      <div className="v-line" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-12">
                <div className="right-side-foot">
                  <h3>NewsLetter</h3>
                  <a href="#">support@svad.com</a>
                  <p>Your Email</p>

                  <NavLink
                    to={userCred !== null ? "" : "/signup"}
                    className="more"
                    style={{ fontSize: 15 }}
                  >
                    Signup
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer-1 */}
        {/* footer-2 */}
        <div id="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="footer-bottom-heading">
                  <h3 style={{ textAlign: "left" }}> DNGH</h3>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div className="footer-bottom-para">
                  <p>@ 2024 svad.com all rights reserved</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12">
                <div
                  className="footer-social-icon"
                  style={{ textAlign: "right" }}
                >
                  <p>Find us on</p>
                  <div className="insta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={16}
                      width={14}
                      viewBox="0 0 448 512"
                      className="svg-1"
                    >
                      <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                    </svg>
                  </div>
                  <div className="insta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={16}
                      width={14}
                      viewBox="0 0 448 512"
                      className="svg-1"
                    >
                      <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                    </svg>
                  </div>
                  <div className="insta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={16}
                      width={16}
                      viewBox="0 0 512 512"
                      className="svg-1"
                    >
                      <path d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z" />
                    </svg>
                  </div>
                  <div className="insta">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={16}
                      width={20}
                      viewBox="0 0 640 512"
                      className="svg-1"
                    >
                      <path d="M180.5 74.3C80.8 74.3 0 155.6 0 256S80.8 437.7 180.5 437.7 361 356.4 361 256 280.2 74.3 180.5 74.3zm288.3 10.6c-49.8 0-90.2 76.6-90.2 171.1s40.4 171.1 90.3 171.1 90.3-76.6 90.3-171.1H559C559 161.5 518.6 84.9 468.8 84.9zm139.5 17.8c-17.5 0-31.7 68.6-31.7 153.3s14.2 153.3 31.7 153.3S640 340.6 640 256C640 171.4 625.8 102.7 608.3 102.7z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Footer;
