import React from "react";

const KeyFeature = () => {
  return (
    <div>
      <div id="feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12-col-12 text-center">
              <h2 className="keyfeature-head">Key Features</h2>
              <div className="line" />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_374_1_ajnyhy.png"
                      alt="5%"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>5% Fee</h2>
                    <p>(Lowest's Industry)</p>
                  </div>
                </div>
                <p className="feature-block-para">
                  Investment fees are fees charged to be use financial products.
                </p>
              </div>
            </div>
            <br />
            <div className="col-lg-3 col-md-6 col-12">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_375_1_rq30g8.png"
                      alt="payment"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      Multiple Payment Method
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Multiple payment gateway helps customer with more convenient
                  and flexible payemnt choices.
                </p>
              </div>
            </div>
            <br />
            <div className="col-lg-3 col-md-6 col-12">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_376_1_g8oi9t.png"
                      alt="security"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      Safe and Secure Transfer
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Secure file transfer are a way to safely share data using
                  encryption and secure protocol.
                </p>
              </div>
            </div>
            <br />
            <div className="col-lg-3 col-md-6 col-12">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_377_1_fruxuj.png"
                      alt="reward"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      5% Reward with Reward pods
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Rewards points are considered to be one of the most attractive
                  benefits.
                </p>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12 key-feature-block">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_374_1_ajnyhy.png"
                      alt="5%"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>5% Fee</h2>
                    <p>(Lowest's Industry)</p>
                  </div>
                </div>
                <p className="feature-block-para">
                  Investment fees are fees charged to be use financial products.
                </p>
              </div>
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_375_1_rq30g8.png"
                      alt="payment"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      Multiple Payment Method
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Multiple payment gateway helps customer with more convenient
                  and flexible payemnt choices.
                </p>
              </div>
            </div>
            <br />

            <br />
            <div className="col-lg-4 col-md-4 col-12">
              <div className="key-features-middle-img">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1727179445/asset-2_pguo6s.png"
                  alt=""
                />
              </div>
            </div>
            <br />
            <div className="col-lg-4 col-md-4 col-12 key-feature-block">
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_376_1_g8oi9t.png"
                      alt="security"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      Safe and Secure Transfer
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Secure file transfer are a way to safely share data using
                  encryption and secure protocol.
                </p>
              </div>
              <div className="feature-block">
                <div className="box d-flex">
                  <div className="box-img">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1715579853/Group_377_1_fruxuj.png"
                      alt="reward"
                    />
                  </div>
                  <div className="box-info">
                    <h2 style={{ fontSize: "24px" }}>
                      5% Reward with Reward pods
                    </h2>
                  </div>
                </div>
                <p className="feature-block-para">
                  Rewards points are considered to be one of the most attractive
                  benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyFeature;
