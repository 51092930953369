import React, { useEffect } from "react";
import "./BlogPage.css";
import LandingNavbar from "../Common/LandingNavbar";
import Footer from "../Common/Footer";
const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LandingNavbar />
      <div className="blog-page-container">
        <div className="container blogcon ">
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/windmill.jpg"
              alt="A windmill"
            />
            <figcaption>
              <a href="#">1</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>

          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam? Lorem, ipsum dolor sit amet
                consectetur adipisicing elit. Ex, deleniti.
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
          <figure className="blog1">
            <img
              src="https://assets.codepen.io/12005/sunset.jpg"
              alt="Sunset and boats"
              className="blogimage"
            />
            <figcaption>
              <a href="#">3</a>
            </figcaption>
            <div className="blogcontent mt-2">
              <div className="tabs">
                <div className="row">
                  <div className="col-4">
                    <button>free</button>
                  </div>
                  <div className="col-4">
                    <button>vlog</button>
                  </div>
                  <div className="col-4">
                    <button>PSD</button>
                  </div>
                </div>
              </div>

              <h6>How was our trip to Maldives?</h6>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque,
                molestias Lorem Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Dolorum, nam?
              </p>
            </div>
            <div className="blogfooter">
              <div className="row">
                <div className="col-6 text-start">
                  <p>13-02-2024</p>
                </div>
                <div className="col-6 text-end">
                  <p>nagpur</p>
                </div>
              </div>
            </div>
          </figure>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogPage;
